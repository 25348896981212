import React from 'react'

import { UppercaseButton } from 'Common/styled'

const Button = props => {
  const { children, ...otherProps } = props
  return (
    <UppercaseButton type={'primary'} size={'large'} {...otherProps}>
      {children}
    </UppercaseButton>
  )
}
export default Button
