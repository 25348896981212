import React, { useState } from "react";
import SnackBar from "../ui/SnackBar/SnackBar";
import { useIntl } from "react-intl";
import Button from "../ui/Button/Button";
import PropTypes from 'prop-types';

const HttpErrorSnackBar = (props) => {
  const intl = useIntl();
  const [isAlertClosed, setAlertClosed] = useState(false);

  return (
    <>
      {!isAlertClosed && (<SnackBar
        message={intl.formatMessage({
          id: `httpErrors.${props.status}`,
        })}
        type="error"
        showIcon
        action={
          <>
            {props.retryHandler && <Button size="small" type="text" onClick={props.retryHandler}>
              {intl.formatMessage({ id: "retry" }).toUpperCase()}
            </Button>}
            <Button size="small" type="text" onClick={() => setAlertClosed(true)}>
              {intl.formatMessage({ id: "close" }).toUpperCase()}
            </Button>
          </>
        }
      />)}
    </>
  );
};

HttpErrorSnackBar.propTypes = {
  status: PropTypes.number.isRequired,
  retryHandler: PropTypes.func,
}

export default React.memo(HttpErrorSnackBar);
