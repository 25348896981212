import { useState, useCallback } from "react";
import { useSelector } from "react-redux";

const useHttp = () => {
  const authData = useSelector((state) => state.auth);
  const [isLoading, setIsLoading] = useState(false);
  const [response, setResponse] = useState(null);

  const sendReq = useCallback(
    async (reqConfig) => {
      setIsLoading(true);

      const newHeaders = { ...reqConfig.headers } ?? {};
      if (authData.token) {
        newHeaders["Authorization"] = `Bearer ${authData.token}`;
      }

      let res, data;
      try {
        res = await fetch(reqConfig.url, {
          method: reqConfig.method ?? "GET",
          headers: newHeaders,
          body: reqConfig.body ? JSON.stringify(reqConfig.body) : null,
        });

        data = await res.json();
      } catch (e) {
        console.error(e);
      }

      setIsLoading(false);
      const response = { status: res?.status ?? 418, data };
      setResponse(response);

      return response;
    },
    [authData.token]
  );

  return {
    isLoading,
    sendReq,
    response,
  };
};

export default useHttp;
