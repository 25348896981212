import React from "react";
import Modal from "../../../../ui/Modal/Modal";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import EmployeeRights from "./EmployeeRights";
import EmployeeHID from "./EmployeeHID";

const EmployeeDetailsModal = (props) => {
  const { employeeData, ...otherProps } = props;
  const intl = useIntl();
  const title = intl.formatMessage({ id: "employeeDetailsModal.title" });

  return (
    <Modal {...otherProps} width={520} title={title} footer={null} centered>
      <table style={{ width: "100%" }}>
        <tbody>
          <tr>
            <td>{intl.formatMessage({ id: "employeeDetailsModal.nameAndSurname" })}:</td>
            <td>{employeeData.lib_pre} {employeeData.lib_nom}</td>
          </tr>
          <tr>
            <td>CAU:</td>
            <td>{employeeData.CAU}</td>
          </tr>
          <tr>
            <td>Badge:</td>
            <td>{employeeData.BADGE_MAGNETICO}</td>
          </tr>
          <tr>
            <td>LDAP:</td>
            <td>{employeeData.LDAP}</td>
          </tr>
          <tr>
            <td>{intl.formatMessage({ id: "employeeDetailsModal.sages" })}:</td>
            <td>{employeeData.SAGES}</td>
          </tr>
          <tr>
            <td>{intl.formatMessage({ id: "employeeDetailsModal.startDate" })}:</td>
            <td>{employeeData.dat_debctt}</td>
          </tr>
          <tr>
            <td>{intl.formatMessage({ id: "employeeDetailsModal.mission" })}:</td>
            <td>{employeeData.lib_mis}</td>
          </tr>
          <tr>
            <td>{intl.formatMessage({ id: "employeeDetailsModal.section" })}:</td>
            <td>{employeeData.lib_raysrv}</td>
          </tr>
          <tr>
            <td>{intl.formatMessage({ id: "employeeDetailsModal.uo" })}:</td>
            <td>{employeeData.lib_uo}</td>
          </tr>
        </tbody>
      </table>
      <br />
      <EmployeeRights employeeData={employeeData} />
      <EmployeeHID cau={employeeData.CAU} />
    </Modal>
  );
};

EmployeeDetailsModal.propTypes = {
  employeeData: PropTypes.object.isRequired,
};

export default EmployeeDetailsModal;
