import React from "react";
import * as styles from "./Spinner.module.css";
import COLORS from "../../../constants/colors";

const Spinner = (props) => {
  const color = props.color ?? COLORS.main;
  const size = props.size ?? "36px";
  const strokeWidth = props.strokeWidth ?? "2px";

  const cssShared = `${strokeWidth} solid`;
  const cssBorder = `${cssShared} ${color}`;
  const cssTransparentBorder = `${cssShared} transparent`;

  const innerDivs = [];
  for (let i = 0; i < 4; i++) {
    innerDivs.push(
      <div
        key={i}
        style={{ border: cssTransparentBorder, borderTop: cssBorder }}
      ></div>
    );
  }

  return (
    <div className={styles.container}>
      {" "}
      <div className={styles.spinner} style={{ height: size, width: size }}>
        {innerDivs}
      </div>
    </div>
  );
};

export default React.memo(Spinner);
