import React from "react";
import { AutoComplete } from "antd";
import Modal from "../../../ui/Modal/Modal";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { shopsActions } from "../../../../store/features/shops/shopsSlice";
import { useIntl } from "react-intl";

const StoresModal = (props) => {
  const { storesList, ...otherProps } = props;
  const dispatch = useDispatch();
  const intl = useIntl();
  const title = intl.formatMessage({ id: "storesModal.title" });
  let timerId;

  const options = storesList?.map((store) => {
    return { id: store.id, value: store.name };
  });

  const selectionHandler = (value) => {
    clearTimeout(timerId);
    timerId = setTimeout(() => {
      const selectedStore = options?.find(
        (x) => x.value.toUpperCase() === value?.toUpperCase()
      );

      if (selectedStore) {
        dispatch(shopsActions.setActiveShop(selectedStore));
      }
    }, 500);
  };

  return (
    <Modal
      {...otherProps}
      title={title}
      footer={null}
      closable={false}
      centered
    >
      <AutoComplete
        allowClear
        style={{ width: "100%" }}
        options={options}
        placeholder={title}
        onChange={selectionHandler}
        filterOption={(inputValue, option) =>
          option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
        }
      />
    </Modal>
  );
};

StoresModal.defaultProps = {
  storesList: [],
};

StoresModal.propTypes = {
  storesList: PropTypes.array,
};

export default StoresModal;
