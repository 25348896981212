import { createSlice } from "@reduxjs/toolkit";
import jwt_decode from "jwt-decode";

const initialState = {
  token: null,
  sessionExp: null,
  username: null,
  sessionText: null,
  userLDAP: null,
};

const authSlice = createSlice({
  name: "authentication",
  initialState: initialState,
  reducers: {
    login(state, action) {
      state.token = action.payload;

      const decoded = jwt_decode(action.payload);
      state.sessionExp = decoded?.exp;
      state.username = `${decoded?.user_id?.surname} ${decoded?.user_id?.name}`;
      state.sessionText = `${decoded?.user_id?.name} ${decoded?.user_id?.surname.charAt(0)}. - ${decoded?.user_id?.storeName} ${decoded?.user_id?.storeNumber}`;
      state.userLDAP = decoded?.user_id?.LDAP;
    },
  },
});

export const { actions: authActions } = authSlice;

export default authSlice.reducer;
