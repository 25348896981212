import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'

// ROUTING
import { BrowserRouter } from 'react-router-dom'
import App from 'Containers/App'

// REDUX
import { Provider } from 'react-redux'
import { store } from './store/store'

import * as serviceWorker from './serviceWorker'
import LangWrapper from 'Components/helper/LangWrapper'
import AuthWrapper from 'Components/helper/AuthWrapper'

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter basename={'/'}>
      <AuthWrapper>
        <LangWrapper>
          <App />
        </LangWrapper>
      </AuthWrapper>
    </BrowserRouter>
  </Provider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
