import React, { useEffect, useState } from "react";
import { IntlProvider } from "react-intl";
import LangContext, {
  availableTranslations as translations,
  defaultLocale,
} from "../../store/context/langContext";

const LangWrapper = (props) => {
  const language = navigator.language.split(/[-_]/)[0];

  const initLocale = translations[language] ? language : defaultLocale;
  const initMessages =
    translations[language]?.messages || translations[defaultLocale].messages;

  const [locale, setLocale] = useState(initLocale);
  const [messages, setMessages] = useState(initMessages);

  useEffect(() => {
    const savedLang = localStorage.getItem("lang");
    if (savedLang) {
      selectLanguage(savedLang);
    }
  }, []);

  const selectLanguage = (key) => {
    const newLocale = translations[key] ? key : defaultLocale;

    setLocale(newLocale);
    setMessages(translations[newLocale].messages);
    localStorage.setItem("lang", newLocale);
  };

  return (
    <LangContext.Provider value={{ locale, selectLanguage }}>
      <IntlProvider messages={messages} locale={locale}>
        {props.children}
      </IntlProvider>
    </LangContext.Provider>
  );
};
export default React.memo(LangWrapper);
