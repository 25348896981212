import { Alert as AntdAlert } from "antd";

import React from "react";

const SnackBar = (props) => {
  const { width, ...otherProps } = props;
  return (
    <div style={{ zIndex: 9999, width: "100%", position: "absolute", bottom: 8 }}>
      <AntdAlert style={{ maxWidth: props.width ?? 720, margin: "auto" }} {...otherProps} />
    </div>
  );
};

export default React.memo(SnackBar);
