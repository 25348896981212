import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  employees: null,
};

const employeesSlice = createSlice({
  name: "employees",
  initialState: initialState,
  reducers: {
    setEmployees(state, action) {
      state.employees = action.payload;
    },
    editEmployee(state, action) {
      const employeeIndex = state.employees?.findIndex((e) => e.CAU === action.payload.CAU);

      state.employees[employeeIndex] = action.payload;
    }
  },
});

export const { actions: employeesActions } = employeesSlice;

export default employeesSlice.reducer;
