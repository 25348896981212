import React from 'react'
import { Link } from 'react-router-dom'

import Menu from 'Components/Menu'
import User from 'Components/User'
import { StyledHeader, HeaderBar, AppLogo } from './styled'

const Header = () => {
  return (
    <>
      <HeaderBar />
      <StyledHeader>
        <Link to={'/'}>
          <AppLogo src={'/assets/img/logo.png'} />
        </Link>
        <Menu />
        <User />
      </StyledHeader>
    </>
  )
}

export default Header
