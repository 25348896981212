const env = process.env.REACT_APP_ENV
const redirectAfterLogin = encodeURIComponent(window.location.href)
const envConfig = {
  dev: {
    LOGIN_URL: `https://test-lmit-gestione-badge-nprd-ping-ybmvspv7ca-ew.a.run.app/sso/auth/?sso&redirectAfterLogin=${redirectAfterLogin}`,
    API_ORIGIN: "https://gestionebadge-api.test.cloud.leroymerlin.it/api/v1",
  },
  test: {
    LOGIN_URL: `https://test-lmit-gestione-badge-nprd-ping-ybmvspv7ca-ew.a.run.app/sso/auth/?sso&redirectAfterLogin=${redirectAfterLogin}`,
    API_ORIGIN: "https://gestionebadge-api.test.cloud.leroymerlin.it/api/v1",
  },
  prod: {
    LOGIN_URL: `https://prod-lmit-gestione-badge-prd-ping-vfpu2hzpoq-ew.a.run.app/sso/auth/?sso&redirectAfterLogin=${redirectAfterLogin}`,
    API_ORIGIN: "https://gestionebadge-api.cloud.leroymerlin.it/api/v1",
  },
}

export default envConfig[env] ?? envConfig["dev"];
