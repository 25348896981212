import PropTypes from "prop-types";
import Table from "../../../ui/Table/Table";
import Button from "../../../Button";
import { SearchOutlined, EditOutlined } from "@ant-design/icons";
import React from "react";
import { injectIntl } from 'react-intl';
import COLORS from "../../../../constants/colors";
import { Input, Space } from 'antd';
import Highlighter from 'react-highlight-words';

class EmployeesTableWithIntl extends React.Component {
  state = {
    searchText: '',
    searchedColumn: '',
  };

  getColumnSearchProps = (dataIndex, dataIndexLabel) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`${this.props.intl.formatMessage({ id: "filter" })} ${dataIndexLabel ?? dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            {this.props.intl.formatMessage({ id: "filter" })}
          </Button>
          <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? COLORS.darkMain : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    },
    render: text =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: COLORS.lightMain, padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: '' });
  };

  render() {
    const columns = [
      {
        title: this.props.intl.formatMessage({ id: "surname" }),
        dataIndex: "lib_nom",
        key: "surname",
        defaultSortOrder: "descend",
        sorter: (a, b) => a.lib_pre.localeCompare(b.lib_pre),
        ...this.getColumnSearchProps('lib_nom'),
      },
      {
        title: this.props.intl.formatMessage({ id: "name" }),
        dataIndex: "lib_pre",
        key: "name",
        sorter: (a, b) => a.lib_pre.localeCompare(b.lib_pre),
        ...this.getColumnSearchProps('lib_pre'),
      },
      {
        title: "CAU",
        dataIndex: "CAU",
        key: "cau",
        sorter: (a, b) => {
          return a.CAU - b.CAU;
        },
        ...this.getColumnSearchProps('CAU'),
      },
      {
        title: "LDAP",
        dataIndex: "LDAP",
        key: "ldap",
        sorter: (a, b) => {
          return a.LDAP - b.LDAP;
        },
        ...this.getColumnSearchProps('LDAP'),
      },
      {
        title: "SAGES",
        dataIndex: "SAGES",
        key: "sages",
        sorter: (a, b) => {
          return a.SAGES - b.SAGES;
        },
        ...this.getColumnSearchProps('SAGES'),
      },
      {
        title: this.props.intl.formatMessage({ id: "description" }),
        dataIndex: "beh3_desc",
        key: "desc",
        ...this.getColumnSearchProps('beh3_desc', this.props.intl.formatMessage({ id: "description" })),
      },
      {
        title: this.props.intl.formatMessage({ id: "actions" }),
        key: "actions",
        render: (_, record) => (
          <Button
            //shape="circle"
            icon={<EditOutlined />}
            onClick={() => this.props.onRowClickHandler(record)}
            title={this.props.intl.formatMessage({ id: "employee.details" })}
          />
        ),
      },
    ];

    const data = this.props.employeesList?.map((employee) => {
      return { ...employee, key: employee.CAU };
    });

    return (
      <Table
        columns={columns}
        dataSource={data}
        sticky
        bordered
        pagination={{ pageSize: 8, showSizeChanger: false }}
      />
    );
  }
}

const EmployeesTable = injectIntl(EmployeesTableWithIntl);

EmployeesTable.defaultProps = {
  employeesList: [],
};

EmployeesTable.propTypes = {
  employeesList: PropTypes.array,
  onRowClickHandler: PropTypes.func.isRequired,
};

export default EmployeesTable;
