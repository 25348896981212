import React from "react";
import { useCallback, useEffect, useState } from "react";
import useHttp from "../../../hooks/useHttp";
import Spinner from "../../ui/Spinner/Spinner";
import { GET_ALL_STORES } from "../../../constants/path";
import * as styles from "./HomePage.module.css";
import StoresModal from "./components/StoresModal";
import HttpErrorsSnackbar from "../../shared/HttpErrorsSnackbar";
import { useDispatch, useSelector } from "react-redux";
import { shopsActions } from "../../../store/features/shops/shopsSlice";
import EmployeesTable from "./components/employeesTable";
import useEmployees from "../../../hooks/useEmployees";
import { useIntl } from "react-intl";
import EmployeeDetailsModal from "./components/EmployeeDetailsModal/EmployeeDetailsModal";
import { PageTitle } from "Common/styled";

const HomePage = () => {
  const intl = useIntl();
  const {
    isLoading: areStoresLoading,
    sendReq: sendStoresReq,
    response: storesRes,
  } = useHttp();
  const { areEmployeesLoading, employeesRes, fetchEmployees, activeShop, savedEmployees } =
    useEmployees();
  const dispatch = useDispatch();
  const shopList = useSelector((state) => state.shops.shopList);
  const [activeEmployee, setActiveEmployee] = useState(null);

  const fetchStores = useCallback(() => {
    sendStoresReq({
      url: GET_ALL_STORES,
    }).then((res) => {
      if (res?.status === 200) {
        dispatch(shopsActions.setShopList(res.data));

        if (res?.data?.length === 1) {
          dispatch(shopsActions.setActiveShop({id: res.data[0].id, value: res.data[0].name}));
        }
      }
    });
  }, [sendStoresReq, dispatch]);

  const showEmployeeDetails = (employee) => {
    setActiveEmployee(employee);
  }

  useEffect(() => {
    if(!shopList) {
      fetchStores();
    }
  }, [fetchStores, shopList]);

  return (
    <div className={styles.tbody}>
      <PageTitle>{intl.formatMessage({ id: "homePage.title" }).toUpperCase()}</PageTitle>
      <div className={styles[".employees-container"]}>
        {(areStoresLoading || areEmployeesLoading) && <Spinner />}
        <EmployeesTable
        onRowClickHandler={showEmployeeDetails}
          employeesList={employeesRes?.status === 200 && !areEmployeesLoading ? savedEmployees : []}
        />
      </div>
      {storesRes?.status === 200 && !activeShop && (
        <StoresModal visible storesList={storesRes?.data} />
      )}
      {activeEmployee && (
        <EmployeeDetailsModal visible employeeData={activeEmployee} onCancel={() => setActiveEmployee(null)} />
      )}
      {!areStoresLoading &&
        storesRes?.status !== undefined &&
        storesRes?.status !== 200 && (
          <HttpErrorsSnackbar
            status={storesRes?.status}
            retryHandler={fetchStores}
          />
        )}
      {!areEmployeesLoading &&
        employeesRes?.status !== undefined &&
        employeesRes?.status !== 200 && (
          <HttpErrorsSnackbar
            status={employeesRes?.status}
            retryHandler={fetchEmployees}
          />
        )}
    </div>
  );
};

export default HomePage;
