import { useCallback, useEffect } from "react";
import useHttp from "./useHttp";
import { GET_EMPLOYEES_BY_STORE } from "../constants/path";
import { useDispatch, useSelector } from "react-redux";
import { employeesActions } from "../store/features/employees/employeeSlice";

const useEmployees = () => {
  const {
    isLoading: areEmployeesLoading,
    sendReq: sendEmployeesReq,
    response: employeesRes,
  } = useHttp();
  const dispatch = useDispatch();
  const activeShop = useSelector((state) => state.shops.activeShop);
  const savedEmployees = useSelector((state) => state.employees.employees);

  const fetchEmployees = useCallback(() => {
    if (activeShop) {
      sendEmployeesReq({
        url: `${GET_EMPLOYEES_BY_STORE}/${activeShop.id}`,
      }).then((res) => {
        if (res.status === 200) {
          dispatch(employeesActions.setEmployees(res.data));
        }
      });
    }
  }, [activeShop, sendEmployeesReq, dispatch]);

  useEffect(() => {
    if (!savedEmployees) {
      fetchEmployees();
    }
  }, [savedEmployees, fetchEmployees]);

  return {
    areEmployeesLoading,
    employeesRes: employeesRes,
    fetchEmployees,
    activeShop,
    savedEmployees,
  };
};

export default useEmployees;
