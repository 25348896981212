import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Switch, Route } from 'react-router-dom'

import Page404 from 'Containers/404'
import Header from 'Components/Header'
import Footer from 'Components/Footer'
import FeedbackButton from 'Components/FeedbackButton'
import { WhiteLayout, PaddedContent } from 'Common/styled'
import HomePage from 'Components/pages/HomePage/HomePage'

class App extends Component {
  static propTypes = {
    user: PropTypes.object,
  }
  componentDidMount() {
    const { authData } = this.props
    
    this.tangram = new window.Tangram({
      productId: '41d73de5-d978-4059-a2a2-8c8251171eeb',
      userLdap: authData?.userLDAP,
      buttonOptions: {
        // This options is needed so you don't have two buttons.
        noShowOnStartup: true,
      },
    })
  }
  render() {
    return (
      <WhiteLayout>
        <Header
          hasTour={false}
        />
        <PaddedContent>
          <Switch>
            <Route component={HomePage} exact path={'/'} />
            <Route component={Page404} />
          </Switch>
        </PaddedContent>
        <Footer />
        <FeedbackButton onClick={() => this.tangram.open()} />
      </WhiteLayout>
    )
  }
}

const mapStateToProps = state => ({
  authData: state.auth,
})

export default connect(mapStateToProps)(App)
