import React from 'react'
import { Menu } from 'antd'
import { Link, useLocation } from 'react-router-dom'
const CustomMenu = props => {
  const location = useLocation()
  return (
    <Menu
      mode={'horizontal'}
      selectedKeys={[location.pathname]}
      style={{ lineHeight: '57px' }}>
      <Menu.Item key={'/'}>
        <Link to={'/'}>{'Home'}</Link>
      </Menu.Item>
    </Menu>
  )
}
export default CustomMenu
