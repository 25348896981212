import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import env from "../../common/config";
import { authActions } from "../../store/features/auth/authSlice";
import Spinner from "../ui/Spinner/Spinner";
import { getCookie, setCookie } from 'Common/cookies';

const AuthWrapper = (props) => {
  const token = useSelector((state) => state.auth.token);
  const expiry = useSelector((state) => state.auth.sessionExp);
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const paramsToken = queryParams.get("token");
    
    if (!token) {
      const authCookie = getCookie('auth_token');

      if (!authCookie && !paramsToken) {
        window.location.href = env.LOGIN_URL;
      } else {
        dispatch(authActions.login(authCookie || paramsToken));
        if (!authCookie) {
          setCookie('auth_token', paramsToken);
        }
      }
    } else {
      const diff = new Date(expiry*1000).getTime() - new Date().getTime();
      setTimeout(() => {
        console.warn("Session expired!");
        window.location.href = env.LOGIN_URL;
      }, diff);
    }

    if (queryParams.has('token')) {
      queryParams.delete('token');
      history.replace(`${location.pathname}?${queryParams.toString()}`);
    }
  }, [token, dispatch, location, expiry, history]);

  return token ? props.children : <Spinner />;
};

export default React.memo(AuthWrapper);
