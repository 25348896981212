import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  shopList: null,
  activeShop: null,
  availableRights: null,
};

const shopsSlice = createSlice({
  name: "shops",
  initialState: initialState,
  reducers: {
    setActiveShop(state, action) {
      state.activeShop = action.payload;
    },
    setShopList(state, action) {
      state.shopList = action.payload;
    },
    setRights(state, action) {
      state.availableRights = action.payload;
    }
  },
});

export const { actions: shopsActions } = shopsSlice;

export default shopsSlice.reducer;
