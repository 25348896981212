import { Select as AntdSelect } from "antd";

import React from "react";

const Select = (props) => {
  return <AntdSelect {...props} />;
};

export default React.memo(Select);

export const { Option } = AntdSelect;
