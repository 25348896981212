import env from "../common/config";

export const GET_ALL_STORES = `${env.API_ORIGIN}/stores`;
export const GET_EMPLOYEES_BY_STORE = `${env.API_ORIGIN}/employees/storeNumber`;
export const GET_ALL_RIGHTS_FOR_STORE = `${env.API_ORIGIN}/perslmBehaviorIds/codesAvailablesFor/storeNumber`;
export const GET_EMPLOYEE_RIGHT = `${env.API_ORIGIN}/perslmBehaviorIds/codes/storeNumber`;
export const EDIT_EMPLOYEE_RIGHT = `${env.API_ORIGIN}/perslmBehaviorIds/upsertCodeFor/storeNumber`;
export const DELETE_RIGHT = `${env.API_ORIGIN}/perslmBehaviorIds/deleteCodeFor/storeNumber`;
export const GET_HID = `${env.API_ORIGIN}/perslmCauHids/hid/cau`;
export const EDIT_HID = `${env.API_ORIGIN}/perslmCauHids/hid`;
export const DELETE_HID = `${env.API_ORIGIN}/perslmCauHids/hid/cau`;