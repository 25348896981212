import React from 'react'
import { Avatar } from 'antd'
import { UserOutlined } from '@ant-design/icons'
import { Username } from './styled'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { shopsActions } from 'src/store/features/shops/shopsSlice'
import { employeesActions } from 'src/store/features/employees/employeeSlice'

const User = () => {
  const intl = useIntl()
  const shopData = useSelector(state => state.shops)
  const username = useSelector(state => state.auth.username)
  const dispatch = useDispatch()
  const isAdmin = shopData?.shopList?.length > 1

  const changeActiveStore = () => {
    if (isAdmin) {
      dispatch(shopsActions.setActiveShop(null))
      dispatch(shopsActions.setRights(null))
      dispatch(employeesActions.setEmployees(null))
    }
  }

  return (
    <div onClick={changeActiveStore}>
      <Avatar
        style={{ cursor: isAdmin ? 'pointer' : 'default' }}
        title={
          isAdmin ? intl.formatMessage({ id: 'homePage.changeStore' }) : ''
        }
        size={'small'}
        icon={<UserOutlined />}
      />
      <Username>
        {username}
        {shopData?.activeShop ? ` - Sede ${shopData?.activeShop?.id}` : ''}
      </Username>
    </div>
  )
}

export default User
