import React from "react";
import translations_it from "../../constants/translations/it.json";
import translations_en from "../../constants/translations/en.json";

const LangContext = React.createContext();

export const availableTranslations = {
  it: { messages: translations_it, label: "Italiano" },
  en: { messages: translations_en, label: "English" },
};

export const defaultLocale = "it";

export default LangContext;
