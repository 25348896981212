import { StyledFooter } from './styled'
import React, { useContext, useEffect, useState } from 'react'
import LangContext, {
  availableTranslations as translations,
} from '../../store/context/langContext'
import Select, { Option } from '../ui/Select/Select';
import * as styles from "./Footer.module.css";

const Footer = () => {
  const langContext = useContext(LangContext)

  const [locale, setLocale] = useState(langContext.locale)

  useEffect(() => {
    setLocale(langContext.locale)
  }, [langContext.locale])

  const langChangeHandler = value => {
    langContext.selectLanguage(value)
  }

  const langOptions = []
  for (let translation in translations) {
    langOptions.push(
      <Option key={translation} value={translation}>
        {translations[translation].label}
      </Option>
    )
  }

  return (
    <footer className={styles.footer}>
      <StyledFooter style={{display: "flex", alignItems: "center"}}>
        <small>v{process.env.REACT_APP_VERSION}</small>
      </StyledFooter>
      {false && <Select style={{marginLeft: 8}} value={locale} onChange={langChangeHandler}>
        {langOptions}
      </Select>}
    </footer>
  )
}

export default React.memo(Footer)
