import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Input } from 'antd'
import { useIntl } from 'react-intl'
import useHttp from 'src/hooks/useHttp'
import { DELETE_HID, EDIT_HID, GET_HID } from 'src/constants/path'
import HttpErrorsSnackbar from '../../../../shared/HttpErrorsSnackbar'
import ReactDOM from 'react-dom';

const EmployeeHID = props => {
  const { cau } = props;
  const intl = useIntl();
  const {
    isLoading: isHIDLoading,
    sendReq: sendHIDReq,
    response: HIDRes,
  } = useHttp();
  const {
    isLoading: isEditHIDLoading,
    sendReq: sendEditHIDReq,
    response: editHIDRes,
  } = useHttp();
  const {
    isLoading: isdeleteHIDLoading,
    sendReq: sendDeleteHIDReq,
    response: deleteHIDRes,
  } = useHttp();
  const [editMode, setEditMode] = useState(false)
  const [newHID, setNewHID] = useState(undefined)

  const saveChanges = () => {
    if (newHID === undefined || !isHIDValid()) {
      return;
    }

    if (!newHID || newHID === '') {
      sendDeleteHIDReq({
        url: `${DELETE_HID}/${cau}`,
        method: 'DELETE',
      }).then(res => {
        if (res.status === 200) {
          setEditMode(false);
        }
      })
    } else {
      sendEditHIDReq({
        url: `${EDIT_HID}/${newHID}/cau/${cau}`,
        method: 'PATCH',
      }).then(res => {
        if (res.status === 200) {
          setEditMode(false);
        }
      })
    }
  }

  const isHIDValid = () => {
    return newHID === undefined || newHID?.length === 5 || newHID?.length === 0;
  }

  useEffect(() => {
    sendHIDReq({
      url: `${GET_HID}/${cau}`,
    })
  }, [sendHIDReq, cau]);

  const isWaiting = isHIDLoading || isEditHIDLoading || isdeleteHIDLoading;

  const httpSnackbars = (
    <>
      {!isHIDLoading &&
        HIDRes?.status !== undefined &&
        HIDRes?.status !== 200 && (
          <HttpErrorsSnackbar status={HIDRes?.status} />
        )}
      {!isdeleteHIDLoading &&
        deleteHIDRes?.status !== undefined &&
        deleteHIDRes?.status !== 200 && (
          <HttpErrorsSnackbar status={deleteHIDRes?.status} retryHandler={saveChanges} />
        )}
      {!isEditHIDLoading &&
        editHIDRes?.status !== undefined &&
        editHIDRes?.status !== 200 && (
          <HttpErrorsSnackbar status={editHIDRes?.status} retryHandler={saveChanges} />
        )}
    </>
  );

  return (
    <>
      {ReactDOM.createPortal(
        httpSnackbars,
        document.getElementById('snackbar-root')
      )}
      <div style={{ marginTop: 16, display: 'flex', alignItems: 'center' }}>
        <div style={{ width: 66 }}>HID:</div>
        <div
          style={{ position: 'relative', width: '100%' }}
          onClick={() => setEditMode(true)}>
          {!editMode && (
            <div
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                cursor: 'pointer',
                zIndex: 9999,
              }}></div>
          )}
          <Input.Search
            type="number"
            placeholder="HID"
            enterButton={intl.formatMessage({
              id: 'save',
            })}
            loading={isWaiting}
            disabled={!editMode || isWaiting}
            value={newHID !== undefined ? newHID : HIDRes?.data[0]?.HID}
            onChange={e => {
              setNewHID(e.target.value)
            }}
            onSearch={saveChanges}
          />
        </div>
      </div>
      <div style={{ color: "red", opacity: !isHIDValid() ? 1 : 0 }}>{intl.formatMessage({ id: 'hid.notValid' })}</div>
    </>
  )
}

EmployeeHID.propTypes = {
  cau: PropTypes.string.isRequired,
}

export default EmployeeHID
