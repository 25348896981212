import React from 'react'
import { useCallback, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import {
  DELETE_RIGHT,
  EDIT_EMPLOYEE_RIGHT,
  GET_ALL_RIGHTS_FOR_STORE,
  GET_EMPLOYEE_RIGHT,
} from '../../../../../constants/path'
import useHttp from '../../../../../hooks/useHttp'
import { shopsActions } from '../../../../../store/features/shops/shopsSlice'
import HttpErrorsSnackbar from '../../../../shared/HttpErrorsSnackbar'
import Button from '../../../../ui/Button/Button'
import Select, { Option } from '../../../../ui/Select/Select'
import { employeesActions } from '../../../../../store/features/employees/employeeSlice'
import ReactDOM from 'react-dom'

const EmployeeRights = props => {
  const { employeeData: employee } = props
  const intl = useIntl()
  const {
    isLoading: areRightsLoading,
    sendReq: sendRightsReq,
    response: rightsRes,
  } = useHttp()
  const {
    isLoading: isEmployeeRightLoading,
    sendReq: sendEmployeeRightReq,
    response: employeeRightRes,
  } = useHttp()
  const {
    isLoading: isCancelRightLoading,
    sendReq: sendCancelRightReq,
    response: cancelRightRes,
  } = useHttp()
  const {
    isLoading: isUpdateRightLoading,
    sendReq: sendUpdateRightReq,
    response: updateRightRes,
  } = useHttp()
  const dispatch = useDispatch()
  const rights = useSelector(state => state.shops.availableRights)
  const activeShop = useSelector(state => state.shops.activeShop)
  const [selectedRight, setSelectedRight] = useState(undefined)
  const [newRight, setNewRight] = useState(undefined)

  const fetchAllRights = useCallback(() => {
    sendRightsReq({
      url: `${GET_ALL_RIGHTS_FOR_STORE}/${activeShop?.id}`,
    }).then(res => {
      if (res?.status === 200) {
        dispatch(shopsActions.setRights(res.data))
      }
    })
  }, [sendRightsReq, dispatch, activeShop])

  const fetchEmployeeRight = useCallback(() => {
    sendEmployeeRightReq({
      url: `${GET_EMPLOYEE_RIGHT}/${activeShop?.id}/cau/${employee.CAU}`,
    })
  }, [sendEmployeeRightReq, activeShop, employee.CAU])

  const putEmployeeRight = useCallback(
    rightCode => {
      sendUpdateRightReq({
        url: `${EDIT_EMPLOYEE_RIGHT}/${activeShop?.id}/cau/${employee.CAU}/code/${rightCode}`,
        method: 'PATCH',
      }).then(res => {
        if (res.status === 200) {
          const updated = { ...employee, beh3_desc: res.data[0]?.descrizione }
          dispatch(employeesActions.editEmployee(updated))
        }
      })
    },
    [sendUpdateRightReq, dispatch, activeShop, employee]
  )

  const deleteEmployeeRight = useCallback(() => {
    sendCancelRightReq({
      url: `${DELETE_RIGHT}/${activeShop?.id}/cau/${employee.CAU}`,
      method: 'DELETE',
    }).then(res => {
      if (res.status === 200) {
        const updated = { ...employee, beh3_desc: null }
        dispatch(employeesActions.editEmployee(updated))
      }
    })
  }, [dispatch, sendCancelRightReq, activeShop, employee])

  const rightUpdateHandler = () => {
    if (newRight) {
      putEmployeeRight(newRight)
    } else {
      deleteEmployeeRight()
    }
    setSelectedRight(newRight)
    setNewRight(undefined)
  }

  const rightsChangeHandler = value => {
    setNewRight(value)
    setSelectedRight(value)
  }

  useEffect(() => {
    if (!rights) {
      fetchAllRights()
    }
    fetchEmployeeRight()
  }, [fetchAllRights, fetchEmployeeRight, rights, rightsRes])

  const isWaiting =
    areRightsLoading ||
    isEmployeeRightLoading ||
    isCancelRightLoading ||
    isUpdateRightLoading
  const allRights = rights ?? rightsRes?.data

  const rightsOptions = allRights?.map(e => {
    return (
      <Option key={e.cod_beh} value={e.cod_beh}>
        {e.descrizione}
      </Option>
    )
  })

  const httpSnackbars = (
    <>
      {!areRightsLoading &&
        rightsRes?.status !== undefined &&
        rightsRes?.status !== 200 && (
          <HttpErrorsSnackbar
            status={rightsRes?.status}
            retryHandler={fetchAllRights}
          />
        )}
      {!isEmployeeRightLoading &&
        employeeRightRes?.status !== undefined &&
        employeeRightRes?.status !== 200 && (
          <HttpErrorsSnackbar
            status={employeeRightRes?.status}
            retryHandler={fetchEmployeeRight}
          />
        )}
      {!isCancelRightLoading &&
        cancelRightRes?.status !== undefined &&
        cancelRightRes?.status !== 200 && (
          <HttpErrorsSnackbar
            status={cancelRightRes?.status}
            retryHandler={deleteEmployeeRight}
          />
        )}
      {!isUpdateRightLoading &&
        updateRightRes?.status !== undefined &&
        updateRightRes?.status !== 200 && (
          <HttpErrorsSnackbar status={updateRightRes?.status} />
        )}
    </>
  )

  return (
    <>
      {ReactDOM.createPortal(
        httpSnackbars,
        document.getElementById('snackbar-root')
      )}
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div style={{ width: 66 }}>
          {intl.formatMessage({ id: 'employeeDetailsModal.rights' })}:
        </div>
        <Select
          style={{ width: '320px' }}
          value={
            selectedRight === undefined
              ? employeeRightRes?.data[0]?.cod_beh ?? null
              : selectedRight
          }
          placeholder={intl.formatMessage({
            id: 'employeeRights.placeholder',
          })}
          disabled={isWaiting}
          loading={isWaiting}
          onChange={rightsChangeHandler}>
          <Option value={null}>{intl.formatMessage({ id: 'none' })}</Option>
          {rightsOptions}
        </Select>
        <Button
          disabled={isWaiting || newRight === undefined}
          type="primary"
          onClick={rightUpdateHandler}>
          {intl.formatMessage({
            id: 'save',
          })}
        </Button>
      </div>
    </>
  )
}

export default EmployeeRights
